import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import AppConstants from "Constants/AppConstants";
import { getApplicant } from "Redux/Actions/ApplicationAction";
import OnlineBanking from "./OnlineBanking";

import {
  doGetPasswordCriteria,
  enableOnlineBanking,
  getDebitCardNumber,
  setOnlineBanking,
  uploadOnlineBanking,
} from "./Actions/OnlineBankingAction";
import {
  HTTP_STATUS,
  DEBIT_CARD_ONLINE_BANKING,
} from "../../Communication/Constants";
import MODALMESSAGES from "../../Constants/Messages";
import { generate } from "generate-password";
import fundMethod from "../FundMethod/FundMethod";

class OnlineBankingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false, // default state for popup modal
      modalType: "", // popup modal type (success or error)
      modal: {
        title: "", // popup modal title
        description: "", // popup modal description
      },
      debitCardNumber: "",
      onlineBankingProductId: null,
      autoGenPassword: "",
    };
  }

  componentDidMount = () => {
    const {
      doGetPswdCriteria,
      passwdCriteria,
      onlineBankingInfo,
      applicantData,
      finInfo,
      doGetDebitCardNumber,
      fundProduct,
    } = this.props;
    console.log("OnlineBankingContainer");

    if (!passwdCriteria) {
      doGetPswdCriteria();
    }
    if (
      (finInfo.showOnlineBankingMemberNumber &&
        finInfo.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING) ||
      finInfo.showDebitCardNumber
    ) {
      let olbApplicationId = onlineBankingInfo.applicationId
        ? onlineBankingInfo.applicationId
        : sessionStorage.getItem(AppConstants.SESSION.OLB_APPLICATION_ID);
      if (
        !olbApplicationId ||
        olbApplicationId === "" ||
        olbApplicationId === "null"
      ) {
        olbApplicationId = fundProduct.applicationId;
      }
      let olbDebitCardProductId = onlineBankingInfo.debitCardProductId
        ? onlineBankingInfo.debitCardProductId
        : sessionStorage.getItem(AppConstants.SESSION.OLB_PRODUCT_ID);
      if (
        !olbDebitCardProductId ||
        olbDebitCardProductId === "" ||
        olbDebitCardProductId === "null"
      ) {
        olbDebitCardProductId = fundProduct.debitProductId;
      }
      doGetDebitCardNumber(
        applicantData.id,
        olbApplicationId,
        olbDebitCardProductId,
        (res) => {
          console.log("debitCardNumber", res);
          if (res && res.status === HTTP_STATUS.OK) {
            this.setState({
              debitCardNumber: res.data?.debitCardNumber,
            });
          }
        }
      );
    }
    console.log(
      "onlineBankingPasswordAutoGen",
      finInfo.onlineBankingPasswordAutoGen
    );
    if (finInfo.onlineBankingPasswordAutoGen) {
      const autoGenPassword = generate(finInfo.onlineBankingPasswordAutoGen);
      console.log("autoGenPassword", autoGenPassword);
      this.setState({ autoGenPassword });
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("componentDidUpdate");
    const { finInfo } = this.props;
    if (prevProps.finInfo !== finInfo) {
      if (finInfo.onlineBankingPasswordAutoGen) {
        const autoGenPassword = generate(finInfo.onlineBankingPasswordAutoGen);
        console.log("autoGenPassword", autoGenPassword);
        this.setState({ autoGenPassword });
      }
    }
  }

  /**
   *
   * @param {string} password represents
   * password value to be set for the current
   * register user.
   */

  handleSetPasswordRequest = (password) => {
    const { onlineBankingProductId } = this.state;
    const {
      continuehandler,
      doEnableOnlineBanking,
      applicantData,
      onlineBankingInfo,
      doSetOnlineBanking,
      doUploadOnlineBanking,
      fundProduct,
    } = this.props;
    if (password && password.length > 0) {
      let olbApplicationId = onlineBankingInfo.applicationId
        ? onlineBankingInfo.applicationId
        : sessionStorage.getItem(AppConstants.SESSION.OLB_APPLICATION_ID);
      if (
        !olbApplicationId ||
        olbApplicationId === "" ||
        olbApplicationId === "null"
      ) {
        olbApplicationId = fundProduct.applicationId;
      }
      if (onlineBankingProductId) {
        doUploadOnlineBanking(
          applicantData.id,
          olbApplicationId,
          onlineBankingProductId,
          password,
          (res) => {
            if (res && res.status === HTTP_STATUS.OK) {
              doSetOnlineBanking(null, null, null);
              this.setState({
                showModal: true,
                modalType: AppConstants.MODALTYPE.SUCCESS,
                modal: {
                  description: MODALMESSAGES.ONLINE_BANKING.SUCCESS_MSG,
                },
              });
            }
          }
        );
      } else {
        let olbDebitCardProductId = onlineBankingInfo.debitCardProductId
          ? onlineBankingInfo.debitCardProductId
          : sessionStorage.getItem(AppConstants.SESSION.OLB_PRODUCT_ID);
        if (
          !olbDebitCardProductId ||
          olbDebitCardProductId === "" ||
          olbDebitCardProductId === "null"
        ) {
          olbDebitCardProductId = fundProduct.debitProductId;
        }
        doEnableOnlineBanking(
          applicantData.id,
          olbApplicationId,
          olbDebitCardProductId,
          password,
          (res) => {
            console.log("enable response", res);
            if (res && res.status === HTTP_STATUS.OK) {
              const newOnlineBankingProductId = res.data.onlineBankingProductId;
              doUploadOnlineBanking(
                applicantData.id,
                onlineBankingInfo.applicationId,
                newOnlineBankingProductId,
                password,
                (res2) => {
                  if (res2 && res2.status === HTTP_STATUS.OK) {
                    doSetOnlineBanking(null, null, null);
                    this.setState({
                      showModal: true,
                      modalType: AppConstants.MODALTYPE.SUCCESS,
                      modal: {
                        description: MODALMESSAGES.ONLINE_BANKING.SUCCESS_MSG,
                      },
                    });
                  }
                }
              );
              this.setState({
                onlineBankingProductId: newOnlineBankingProductId,
              });
            }
          }
        );
      }
    } else {
      doSetOnlineBanking(null, null, null);
      continuehandler("", AppConstants.APPLICATIONSTEP.ONLINE_BANKING);
    }
  };

  handleSkip = () => {
    const { continuehandler, doSetOnlineBanking } = this.props;
    doSetOnlineBanking(null, null, null);
    continuehandler("", AppConstants.APPLICATIONSTEP.ONLINE_BANKING);
  };

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
  };

  handlePopupModalBtnClick = () => {
    const { continuehandler } = this.props;
    continuehandler("", AppConstants.APPLICATIONSTEP.ONLINE_BANKING);
  };

  render() {
    const {
      modalType,
      modal,
      showModal,
      debitCardNumber,
      autoGenPassword,
    } = this.state;
    const {
      passwdCriteria,
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      finInfo,
      applicantData,
    } = this.props;
    return (
      <OnlineBanking
        handleContinue={this.handleContinue}
        getDataFromServer={getDataFromServer}
        handleBack={handleBack}
        steps={steps}
        activeStepID={activeStepID}
        handleSetPasswordRequest={this.handleSetPasswordRequest}
        passwdCriteria={passwdCriteria.passwordCriteria}
        handleSkip={this.handleSkip}
        type={modalType}
        modal={modal}
        showModal={showModal}
        toggleModal={this.toggleModal}
        handlePopupModalBtnClick={this.handlePopupModalBtnClick}
        finInfo={finInfo}
        applicantData={applicantData}
        debitCardNumber={debitCardNumber}
        autoGenPassword={autoGenPassword}
      />
    );
  }
}

OnlineBankingContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  passwdCriteria: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  doGetPswdCriteria: PropTypes.func.isRequired,
  doEnableOnlineBanking: PropTypes.func.isRequired,
  applicationId: PropTypes.string,
  applicantData: PropTypes.objectOf(PropTypes.any),
  onlineBankingInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  doSetOnlineBanking: PropTypes.func.isRequired,
  doUploadOnlineBanking: PropTypes.func.isRequired,
  finInfo: PropTypes.objectOf(PropTypes.any),
  doGetDebitCardNumber: PropTypes.func.isRequired,
};

OnlineBankingContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  passwdCriteria: {},
  applicationId: null,
  applicantData: null,
  finInfo: {},
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetPswdCriteria: () => dispatch(doGetPasswordCriteria()),
    doGetDebitCardNumber: (
      applicantId,
      applicationId,
      debitCardProductId,
      callback
    ) =>
      dispatch(
        getDebitCardNumber(
          applicantId,
          applicationId,
          debitCardProductId,
          callback
        )
      ),
    doEnableOnlineBanking: (
      applicantId,
      applicationId,
      debitCardProductId,
      password,
      callback
    ) =>
      dispatch(
        enableOnlineBanking(
          applicantId,
          applicationId,
          debitCardProductId,
          password,
          callback
        )
      ),
    doSetOnlineBanking: (
      applicationId,
      debitCardProductId,
      templateId,
      callback
    ) =>
      dispatch(
        setOnlineBanking(
          applicationId,
          debitCardProductId,
          templateId,
          callback
        )
      ),
    doUploadOnlineBanking: (
      applicantId,
      applicationId,
      onlineBankingProductId,
      password,
      callback
    ) =>
      dispatch(
        uploadOnlineBanking(
          applicantId,
          applicationId,
          onlineBankingProductId,
          password,
          callback
        )
      ),
    doGetApplicant: (id, callback) => dispatch(getApplicant(id, callback)),
  };
};
const mapStateToProps = (state) => ({
  updatePasswordStatus: state.UpdatePasswordReducer,
  products: state.VaultReducer.vaultProductList,
  passwdCriteria: state.GetPasswordCriteriaReducer.success,
  doximProducts: state.ApplicationReducer.doximProducts,
  enableDebitCard: state.DebitCardReducer.enableDebitCard,
  isDebitCardAlreadyEnabled: state.DebitCardReducer.isDebitCardEnabled,
  applicationId: state.ApplicationReducer.applicationId,
  applicantData: state.ApplicationReducer.response,
  onlineBankingInfo: state.OnlineBankingReducer,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
  finInfo: state.MarketplaceReducer.finInfo,
  fundProduct: state.VaultReducer.fundProduct,
});

const OnlineBankingWrapper = withApplicantHOC(OnlineBankingContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineBankingWrapper);
// export default OnlineBankingContainer;
