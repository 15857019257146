import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import BusinessDebit from "./BusinessDebit";
import * as ROUTES from "Constants/Routes";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import STRINGS from "../../Constants/Strings";
import { getApplicationList } from "../../Redux/Actions/ApplicationAction";
import { getProductIdInActiveProduct } from "../../Utils/LogicUtilities";
import { HTTP_STATUS } from "../../Communication/Constants";
import { getApplicationProperty } from "../JointApplicant/Actions/JointApplicantAction";
import { updateProductToVault } from "../MarketPlace/Action/MarketPlaceAction";
import {
  addBundleProduct,
  deleteDebitCard,
} from "../DebitCard/Actions/DebitCardAction";

class BusinessDebitContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      products,
      doGetApplicationProperty,
      doximProducts,
      getActiveProduct,
    } = this.props;
    console.log("componentDidMount");
    const activeProduct = getActiveProduct(products);
    this.checkBundleProducts();

    if (doximProducts && doximProducts.length === 0) {
      const reqData = {
        applicationId: activeProduct.applicationId,
      };
      doGetApplicationProperty(reqData);
    }
  }

  checkBundleProducts = () => {
    const {
      products,
      isDebitCardEnabled,
      getActiveProduct,
      bundleProductRelation,
      actionBack,
      doGetBundleProductRelation,
      applicantData,
      doUpdateProductToVault,
      continuehandler,
    } = this.props;
    console.log("checkBundleProducts", bundleProductRelation);
    if (bundleProductRelation?.length <= 0) {
      if (actionBack) {
        this.doHandleBack(AppConstants.APPLICATIONSTEP.DEBIT_CARD);
      } else {
        continuehandler("", AppConstants.APPLICATIONSTEP.DEBIT_CARD);
      }
    }
  };

  handleChange = () => {};

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const {
      continuehandler,
      doAddBundleProduct,
      bundleProductRelation,
      products,
      getActiveProduct,
      doUpdateProductToVault,
      doGetApplicationProperty,
      doDeleteDebitCard,
    } = this.props;
    const { businessDebit } = data;
    const activeProduct = getActiveProduct(products);
    const newProducts = products;
    if (businessDebit) {
      if (activeProduct.debitProductId && activeProduct.debitProductId !== 0) {
        // debit already added
        continuehandler(data, AppConstants.APPLICATIONSTEP.BUSINESS_DEBIT);
      } else {
        doAddBundleProduct(
          bundleProductRelation.applicationId,
          bundleProductRelation[0].bundleProduct.id,
          activeProduct.doximProductId,
          activeProduct.applicantId,
          (res2) => {
            if (res2 && res2.status === HTTP_STATUS.OK) {
              const debitProduct =
                res2.data.doximProducts[
                  res2.data.doximProducts.findLastIndex(
                    (item) =>
                      item.type === STRINGS.DEBIT_CARD.PRODUCT_TYPE_DOXIM &&
                      item.ownerId === activeProduct.applicantId
                  )
                ];
              activeProduct.debitProductId = debitProduct.id;
              newProducts[0] = activeProduct;
              doUpdateProductToVault(newProducts, () => {});
              doGetApplicationProperty(
                { applicationId: activeProduct.applicationId },
                () => {
                  continuehandler(
                    data,
                    AppConstants.APPLICATIONSTEP.BUSINESS_DEBIT
                  );
                }
              );
            }
          }
        );
      }
    } else {
      if (activeProduct.debitProductId && activeProduct.debitProductId !== 0) {
        doDeleteDebitCard(
          bundleProductRelation.applicationId,
          activeProduct.debitProductId,
          (res) => {
            if (res && res.status === HTTP_STATUS.OK) {
              activeProduct.debitProductId = null;
              newProducts[0] = activeProduct;
              doUpdateProductToVault(newProducts, () => {});
              doGetApplicationProperty(
                { applicationId: activeProduct.applicationId },
                () => {}
              );
              continuehandler(
                data,
                AppConstants.APPLICATIONSTEP.BUSINESS_DEBIT
              );
            }
          }
        );
      } else {
        continuehandler(data, AppConstants.APPLICATIONSTEP.BUSINESS_DEBIT);
      }
    }
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      products,
      getActiveProduct,
    } = this.props;

    const activeProduct = getActiveProduct(products);

    return (
      <>
        <BusinessDebit
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          activeProduct={activeProduct}
        />
      </>
    );
  }
}

BusinessDebitContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessDebitContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  doximProducts: state.ApplicationReducer.doximProducts,
  streetTypes: state.LoanDetailsReducer.streetTypes,
  bundleProductRelation: state.GetBundleProductRelationReducer.data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetApplicationList: (callback) => dispatch(getApplicationList(callback)),
    doGetApplicationProperty: (request, callback) =>
      dispatch(getApplicationProperty(request, callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
    doAddBundleProduct: (
      applicationId,
      bundleProductId,
      demandProductId,
      applicantId,
      callback
    ) =>
      dispatch(
        addBundleProduct(
          applicationId,
          bundleProductId,
          demandProductId,
          applicantId,
          callback
        )
      ),
    doDeleteDebitCard: (applicationId, debitProductId, callback) =>
      dispatch(deleteDebitCard(applicationId, debitProductId, callback)),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(BusinessDebitContainer);

const BusinessDebitWrapper = withBusinessHOC(BusinessDebitContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessDebitWrapper);
