import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import AppConstants from "../../Constants/AppConstants";
import DatePicker from "react-datepicker";
import { format, getMonth, getYear, parse, sub, subYears } from "date-fns";
import { Typeahead } from "react-bootstrap-typeahead";
import MaskedInput from "react-text-mask";
import { Element, scroller } from "react-scroll";
import { getSelectedCountry } from "../../Utils/AboutYouUtilities";
import { getProduct } from "../../Utils/CommonUtilities";
import {
  getMemberBenefit,
  getProductIdByAge,
  verifyAge,
} from "../../Utils/LogicUtilities";
import checkErrors from "../../Utils/InputValidator";
import VALIDATIONS from "Constants/ValidationList";

const range = (start, end) => {
  const length = end - start;
  return Array.from({ length }, (_, i) => end - i);
};

const unitTypeList = ["", "Apt", "Suite", "Unit"];
const streetTypeList = [
  "",
  "Ave",
  "Blvd",
  "Bourne",
  "Cres",
  "Dr",
  "Rd",
  "St",
  "Crt",
  "Fwy",
  "Hwy",
  "Pky",
  "Pl",
  "Lane",
  "Way",
];
const directionList = ["", "N", "S", "E", "W", "NE", "NW", "SE", "SW"];

class BusinessKyc extends React.Component {
  constructor(props) {
    super(props);
    this.searchField = React.createRef();
    this.searchField2 = React.createRef();
    let startDate = new Date();
    startDate = subYears(startDate, 100);
    let endDate = new Date();
    endDate = subYears(endDate, 12);
    const minDate = sub(new Date(), { years: 100 });
    const maxDate = sub(new Date(), { years: 12 });
    const years = range(getYear(minDate), getYear(maxDate));
    const {
      fName,
      mName,
      lName,
      dOB,
      address,
      addressLine2,
      addressLine3,
      city,
      province,
      postalCode,
      instntTxnId,
    } = this.props;
    this.state = {
      userInfo: null,
      firstName: fName,
      middleName: mName,
      lastName: lName,
      dateOfBirth: dOB,
      address,
      apartment: addressLine2,
      addressLine3,
      city,
      province,
      unitType: "",
      streetType: "",
      direction: "",
      postalCode,
      currentAddressStreetNumber: "",
      currentAddressStreetName: "",
      mailingAddressLine1: "",
      mailingApartment: "",
      mailingCity: "",
      mailingProvince: "",
      mailingPostalCode: "",
      mailingCountry: "",
      mailingUnitType: "",
      mailingStreetType: "",
      mailingDirection: "",
      scanConfirmation: "",
      mailingSameAsCivic: "",
      errors: {
        firstNameErrorList: [],
        lastNameErrorList: [],
        dateOfBirthErrorList: [],
        addressErrorList: [],
        currentAddressStreetNumberErrorList: [],
        currentAddressStreetNameErrorList: [],
        cityErrorList: [],
        provinceErrorList: [],
        postalCodeErrorList: [],
        mailingAddressLine1ErrorList: [],
        mailingStreetNumberErrorList: [],
        mailingStreetNameErrorList: [],
        mailingCityErrorList: [],
        mailingProvinceErrorList: [],
        mailingPostalCodeErrorList: [],
        mailingCountryErrorList: [],
        scanConfirmationErrorList: [],
        mailingSameAsCivicErrorList: [],
      },
      branch: "",
      accountStatementType: "",
      reasonCode: "",
      editMode: true,
      countriesList: [],
      startDate,
      endDate,
      minDate,
      maxDate,
      years,
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      addressInstanceKey: "",
      selectedAddress: "",
      addressSearchResult: [],
      showSearchResult: false,
      blockAddressEdit: true,
      executeAdressSearch: true,
      selectedAddress2: "",
      addressSearchResult2: [],
      showSearchResult2: false,
      blockAddressEdit2: true,
      executeAdressSearch2: true,
      showModal: false,
      modal: {},
      allowPersonalInfoEdit: false,
      allowAddressInfoEdit: true,
      addressValidationMessage: "",
      promoCode: "",
      referralCode: "",
      allowKycEditName: false,
      allowKycEditDob: false,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const { applicantData } = this.props;

    this.setApplicantInfo(applicantData);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { applicantData } = this.props;
    if (prevProps.applicantData !== applicantData) {
      this.setApplicantInfo(applicantData);
    }
  }

  setFormData = (data) => {
    const { activeProduct, doSearchMember } = this.props;
    console.log("setFormData", data, activeProduct);
    if (activeProduct.ownerApplicantId) {
    } else {
      doSearchMember(
        {
          memberNumber: activeProduct.memberNumber,
          transit: activeProduct.transit,
        },
        (res) => {
          console.log(res);
        }
      );
    }
  };

  setApplicantInfo = () => {
    const {
      applicationCompleteList,
      finInfo,
      instntTxnId,
      inSessionJointApplicant,
      applicantData,
      vaultMember,
      activeProduct,
    } = this.props;
    console.log("setApplicantInfo", instntTxnId, applicantData);
    const selectedAddress = [];
    const selectedAddress2 = [];
    if (applicantData.id) {
      const { member, address } = applicantData;
      // default core banking
      let currentAddress = address.currentAddressLine1;
      let mailingAddress = address.mailingAddressLine1;
      let city = address.currentAddressCity;
      let province = address.currentAddressProvince;
      let { mailingCity } = address;
      let { mailingProvince } = address;
      let apartment = address.currentAddressUnitNumber;
      let mailingApartment = address.mailingUnitNumber;
      console.log(
        "coreBankingType",
        finInfo.coreBankingType,
        address.currentAddressLine2,
        address.currentAddressLine3
      );
      if (finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.RFS) {
        const currentAddressArray = currentAddress.split("-");
        if (currentAddressArray.length > 1) {
          apartment = currentAddressArray[0].trim();
          currentAddress = currentAddressArray[1].trim();
        }
        const mailingAddressArray = mailingAddress.split("-");
        if (mailingAddressArray.length > 1) {
          mailingApartment = mailingAddressArray[0].trim();
          mailingAddress = mailingAddressArray[1].trim();
        }
        if (!city && address.currentAddressLine2) {
          city = address.currentAddressLine2.replace(` ${province}`, "");
        }
        if (!mailingCity && address.mailingAddressLine2) {
          city = address.mailingAddressLine2.replace(` ${mailingProvince}`, "");
        }
      } else if (
        finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.DNA
      ) {
        city = address.currentAddressLine3;
        if (address.currentAddressLine1.indexOf(" - ") > 0) {
          const addr = address.currentAddressLine1.split(" - ");
          apartment = addr[0];
          currentAddress = addr[1];
        }
        if (address.mailingAddressLine1.indexOf(" - ") > 0) {
          const addr = address.mailingAddressLine1.split(" - ");
          mailingApartment = addr[0];
          mailingAddress = addr[1];
        }
      }

      if (finInfo.cityReformat) {
        const cityAndProvince = address.currentAddressLine3;
        console.log("cityAndProvince", cityAndProvince);
        if (cityAndProvince && cityAndProvince.indexOf(" ") > 1) {
          city = cityAndProvince
            .substring(0, cityAndProvince.lastIndexOf(" "))
            .trim();
          province = cityAndProvince
            .substring(cityAndProvince.lastIndexOf(" "))
            .trim();
        }
        const mailingCityAndProvince = address.mailingAddressLineType3;
        if (mailingCityAndProvince && mailingCityAndProvince.indexOf(" ") > 1) {
          mailingCity = mailingCityAndProvince
            .substring(0, mailingCityAndProvince.lastIndexOf(" "))
            .trim();
          mailingProvince = mailingCityAndProvince
            .substring(mailingCityAndProvince.lastIndexOf(" "))
            .trim();
        }
      }

      selectedAddress.push({
        label: currentAddress,
        AddressLine1: currentAddress,
        StateProvince: province,
        PostalCode: address.currentAddressPostalCode,
        City: city,
        ProcessedBy: "",
        Country: "",
      });
      selectedAddress2.push({
        label:
          finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.OVATION
            ? ""
            : mailingAddress,
        AddressLine1: mailingAddress,
        StateProvince: mailingProvince,
        PostalCode: address.mailingPostalCode,
        City: mailingCity,
        ProcessedBy: "",
        Country: address.mailingCountry,
      });
      let allowEdit = true;
      let allowAddressInfoEdit = !!finInfo.allowAddressInfoEdit;
      if (applicationCompleteList && applicationCompleteList.length > 0) {
        console.log("edwin 1");
        allowEdit = false;
        allowAddressInfoEdit = true;
      } else if (allowAddressInfoEdit === false) {
        allowAddressInfoEdit = true;
      }
      if (inSessionJointApplicant) {
        console.log("edwin 2");
        allowEdit = true;
        allowAddressInfoEdit = true;
      } else if (applicantData?.acquireType === "search") {
        console.log("edwin 3");
        allowEdit = false;
      }
      this.setState({
        firstName: inSessionJointApplicant
          ? member.name
          : vaultMember?.name || member.name,
        middleName: inSessionJointApplicant
          ? member.middleName
          : vaultMember?.middleName || member.middleName,
        lastName: inSessionJointApplicant
          ? member.surname
          : vaultMember?.surname || member.surname,
        dateOfBirth: inSessionJointApplicant
          ? member.dob
          : vaultMember?.dob || member.dob,
        address: currentAddress,
        apartment,
        city,
        province,
        postalCode: address.currentAddressPostalCode,
        unitType: address.currentAddressUnitType,
        streetType: address.currentAddressStreetType,
        direction: address.currentAddressStreetDir,
        currentAddressStreetNumber: address.currentAddressStreetNumber,
        currentAddressStreetName: address.currentAddressStreetName,
        mailingAddressLine1: mailingAddress,
        mailingApartment,
        mailingCity,
        mailingProvince,
        mailingPostalCode: address.mailingPostalCode,
        mailingCountry: address.mailingCountry,
        mailingUnitType: address.mailingUnitType,
        mailingStreetType: address.mailingStreetType,
        mailingStreetDir: address.mailingStreetDir,
        mailingStreetNumber: address.mailingStreetNumber,
        mailingStreetName: address.mailingStreetName,
        mailingSameAsCivic: !!address.mailingSameAsCivic,
        editMode: allowEdit,
        allowAddressInfoEdit,
        selectedAddress,
        selectedAddress2,
        blockAddressEdit: !address.currentAddressLine1,
        blockAddressEdit2: !address.mailingAddressLine1,
      });
    } else {
      this.setState({
        firstName: activeProduct?.personal?.firstName,
        middleName: activeProduct?.personal?.surname,
        lastName: activeProduct?.personal?.lastName,
        dateOfBirth: activeProduct?.personal?.dateOfBirth,
        address: activeProduct?.personal?.address,
        apartment: activeProduct?.personal?.currentAddressUnitNumber,
        city: activeProduct?.personal?.city,
        province: activeProduct?.personal?.state,
        postalCode: activeProduct?.personal?.postalCode,
        unitType: activeProduct?.personal?.currentAddressUnitType,
        streetType: activeProduct?.personal?.currentAddressStreetType,
        direction: activeProduct?.personal?.currentAddressStreetDir,
        currentAddressStreetNumber:
          activeProduct?.personal?.currentAddressStreetNumber,
        currentAddressStreetName:
          activeProduct?.personal?.currentAddressStreetName,
        mailingAddressLine1: activeProduct?.personal?.mailingAddressLine1,
        mailingApartment: activeProduct?.personal?.mailingUnitNumber,
        mailingCity: activeProduct?.personal?.mailingCity,
        mailingProvince: activeProduct?.personal?.mailingProvince,
        mailingPostalCode: activeProduct?.personal?.mailingPostalCode,
        mailingCountry: activeProduct?.personal?.mailingCountry,
        mailingUnitType: activeProduct?.personal?.mailingUnitType,
        mailingStreetType: activeProduct?.personal?.mailingStreetType,
        mailingStreetDir: activeProduct?.personal?.mailingStreetDir,
        mailingStreetNumber: activeProduct?.personal?.mailingStreetNumber,
        mailingStreetName: activeProduct?.personal?.mailingStreetName,
        mailingSameAsCivic: activeProduct?.personal?.mailingSameAsCivic,
        allowPersonalInfoEdit: true,
        allowAddressInfoEdit: true,
      });
    }
  };

  setCountriesList(countriesList) {
    this.setState({ countriesList });
  }

  setBranchAndOverderaft(gettingStarted) {
    this.setState({
      branch: gettingStarted.branch,
      accountStatementType: gettingStarted.accountStatementType,
      reasonCode: gettingStarted.reasonCode,
      promoCode: gettingStarted.promoCode,
      referralCode: gettingStarted.referralCode,
    });
  }

  setDOB = (dob) => {
    const { errors } = this.state;
    this.setState({
      dateOfBirth: format(dob, "yyyy-MM-dd"),
      errors: {
        ...errors,
        dateOfBirthErrorList: [],
      },
    });
  };

  onChangeHandler = (event) => {
    const { errors, editMode } = this.state;
    const inputField = event?.target.name;
    console.log("inputField", inputField);
    let eventValue = event?.target.value;
    const index = AppConstants.ABOUT_YOU.EDIT_MODE_INPUTLIST.findIndex(
      (elem) => inputField === elem
    );
    if (
      eventValue &&
      (inputField === "firstName" ||
        inputField === "middleName" ||
        inputField === "lastName" ||
        inputField === "city" ||
        inputField === "mailingCity")
    ) {
      eventValue = eventValue.replace(
        /[^A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð\. \-']/gi,
        ""
      );
    }
    if (editMode || index === -1) {
      this.setState(
        {
          [inputField]: eventValue,
          errors: {
            ...errors,
            [`${event?.target.name}ErrorList`]: [],
          },
        },
        () => {
          if (
            inputField === STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMEREF
          ) {
            this.textInput.focus();
          }
        }
      );
    }
  };

  verifyAddressLength = () => {
    let valid = true;
    const {
      address,
      apartment,
      mailingAddressLine1,
      mailingApartment,
      addressValidationMessage,
      mailingSameAsCivic,
    } = this.state;
    const { finInfo } = this.props;
    console.log(
      "verifyAddressLength ",
      addressValidationMessage,
      mailingSameAsCivic
    );

    const addressLimit = finInfo.addressLimit
      ? finInfo.addressLimit - 3
      : AppConstants.ABOUT_YOU.ADDRESS_LIMIT - 3;

    const addressLength = address?.length + apartment?.length;
    console.log("addressLength ", addressLength, addressLimit);
    if (addressLength > addressLimit) {
      valid = false;
      console.log("invalid address and apartment");
      this.setState({
        showAddressMessage: true,
        modal: {
          title: STRINGS.ABOUTYOU.ADDRESS_LIMIT.TITLE,
          description: `${STRINGS.ABOUTYOU.ADDRESS_LIMIT.ADDRESS_MESSAGE} ${addressLimit} ${STRINGS.ABOUTYOU.ADDRESS_LIMIT.ADDRESS_MESSAGE1}`,
        },
      });
    } else if (!mailingSameAsCivic) {
      const mailingLength =
        mailingAddressLine1?.length + mailingApartment?.length;
      console.log("mailingAddressLength ", mailingLength, addressLimit);
      if (mailingLength > addressLimit) {
        valid = false;
        console.log("invalid mailing address and apartment");
        this.setState({
          showAddressMessage: true,
          modal: {
            title: STRINGS.ABOUTYOU.ADDRESS_LIMIT.TITLE,
            description: `${STRINGS.ABOUTYOU.ADDRESS_LIMIT.MAILING_MESSAGE} ${addressLimit} ${STRINGS.ABOUTYOU.ADDRESS_LIMIT.MAILING_MESSAGE1}`,
          },
        });
      }
    }

    return valid;
  };

  handleContinue = () => {
    const { handleContinue, applicantData, finInfo } = this.props;
    console.log("handleContinue", this.state);
    if (this.verifyData()) {
      if (this.verifyAddressLength()) {
        const {
          address,
          apartment,
          unitType,
          streetType,
          direction,
          city,
          province,
          postalCode,
          currentAddressStreetNumber,
          currentAddressStreetName,
          mailingAddressLine1,
          mailingApartment,
          mailingCity,
          mailingProvince,
          mailingPostalCode,
          mailingCountry,
          mailingSameAsCivic,
          mailingUnitType,
          mailingStreetType,
          mailingStreetDir,
          mailingStreetNumber,
          mailingStreetName,
          firstName,
          lastName,
          middleName,
          dateOfBirth,
        } = this.state;

        let request = {};

        request = {
          address: {
            currentAddressLine1: address,
            currentAddressLine3: "",
            currentAddressCity: city,
            currentAddressProvince: province,
            currentAddressPostalCode: postalCode,
            currentAddressStreetDir: direction,
            currentAddressStreetType: streetType,
            currentAddressUnitNumber: apartment,
            currentAddressUnitType: unitType,
            currentAddressStreetNumber,
            currentAddressStreetName,
            mailingSameAsCivic,
          },
        };

        if (finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.RFS) {
          if (apartment && apartment.trim() !== "") {
            request.address.currentAddressLine1 = `${apartment} - ${address}`;
          }
          if (finInfo.cityReformatRfs) {
            request.address.currentAddressLine2 = `${city} ${province}`;
          }
          request.address.currentAddressStreetName = "";
          request.address.currentAddressStreetNumber = "";
        } else if (
          finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.DNA
        ) {
          if (apartment && apartment.trim() !== "") {
            request.address.currentAddressLine1 = `${apartment} - ${address}`;
          } else {
            request.address.currentAddressLine1 = address;
          }
          request.address.currentAddressUnitNumber = "";
          request.address.currentAddressLine3 = city;
          request.address.currentAddressStreetName = "";
          request.address.currentAddressStreetNumber = "";
        }
        if (finInfo.cityReformat) {
          request.address.currentAddressLine3 = `${city} ${province}`;
        }
        if (mailingSameAsCivic) {
          request = {
            ...request,
            address: {
              ...request.address,
              mailingAddressLineType1: "",
              mailingAddressLine1: "",
              mailingAddressLine2: "",
              mailingAddressLine3: "",
              mailingCity: "",
              mailingProvince: "",
              mailingPostalCode: "",
              mailingCountry: "",
              mailingUnitNumber: "",
              mailingUnitType: "",
              mailingStreetType: "",
              mailingStreetDir: "",
              mailingStreetNumber: "",
              mailingStreetName: "",
            },
          };
        } else {
          request = {
            ...request,
            address: {
              ...request.address,
              mailingAddressLineType1: "",
              mailingAddressLine1,
              mailingCity,
              mailingProvince,
              mailingPostalCode,
              mailingCountry,
              mailingUnitNumber: mailingApartment,
              mailingUnitType,
              mailingStreetType,
              mailingStreetDir,
              mailingStreetNumber,
              mailingStreetName,
            },
          };
          if (finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.RFS) {
            if (mailingApartment && mailingApartment.trim() !== "") {
              request.address.mailingAddressLine1 = `${mailingApartment} - ${mailingAddressLine1}`;
            }
            if (finInfo.cityReformatRfs) {
              request.address.mailingAddressLine2 = `${mailingCity} ${mailingProvince}`;
            }
            request.address.mailingStreetNumber = "";
            request.address.mailingStreetName = "";
          } else if (
            finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.DNA
          ) {
            if (mailingApartment && mailingApartment.trim() !== "") {
              request.address.mailingAddressLine1 = `${mailingApartment} - ${mailingAddressLine1}`;
            }
            request.address.mailingUnitNumber = "";
            request.address.mailingAddressLine3 = mailingCity;
            request.address.mailingStreetNumber = "";
            request.address.mailingStreetName = "";
          }
          if (finInfo.cityReformat) {
            request.address.mailingAddressLine3 = `${mailingCity} ${mailingProvince}`;
          }
        }

        if (applicantData?.id) {
          handleContinue(request);
        } else {
          console.log(this.state);
          handleContinue({
            ...request,
            rep: finInfo.rep,
            firstName,
            lastName,
            surname: lastName,
            middleName,
            dateOfBirth,
            address,
            addressLine2: request.address.currentAddressLine2,
            addressLine3: request.address.currentAddressLine3,
            city: request.address.currentAddressCity,
            state: request.address.currentAddressProvince,
            postalCode: request.address.currentAddressPostalCode,
            currentAddressUnitType: request.address.currentAddressUnitType,
            currentAddressUnitNumber: request.address.currentAddressUnitNumber,
            currentAddressStreetType: request.address.currentAddressStreetType,
            currentAddressStreetDir: request.address.currentAddressStreetDir,
            currentAddressStreetNumber:
              request.address.currentAddressStreetNumber,
            currentAddressStreetName: request.address.currentAddressStreetName,
            mailingSameAsCivic: request.address.mailingSameAsCivic,
            mailingAddressLine1: request.address.mailingAddressLine1,
            mailingUnitNumber: request.address.mailingUnitNumber,
            mailingCity: request.address.mailingCity,
            mailingCountry: request.address.mailingCountry,
            mailingStreetDir: request.address.mailingDirection,
            mailingPostalCode: request.address.mailingPostalCode,
            mailingProvince: request.address.mailingProvince,
            mailingStreetType: request.address.mailingStreetType,
            mailingUnitType: request.address.mailingUnitType,
          });
        }
      }
    }
  };

  verifyData = () => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      address,
      city,
      province,
      postalCode,
      currentAddressStreetNumber,
      currentAddressStreetName,
      mailingAddressLine1,
      mailingCity,
      mailingProvince,
      mailingPostalCode,
      mailingCountry,
      mailingStreetNumber,
      mailingStreetName,
      errors,
      scanConfirmation,
      mailingSameAsCivic,
    } = this.state;
    console.log("verifyData", this.state);
    const { isDesktop, finInfo } = this.props;
    let errorList = { ...errors };
    let valid = true;

    const firstNameErrorList = checkErrors(
      firstName,
      VALIDATIONS.ABOUTYOU.FIRSTNAME
    );
    errorList = { ...errorList, firstNameErrorList };
    if (firstNameErrorList && firstNameErrorList.length > 0) {
      valid = false;
    }

    const lastNameErrorList = checkErrors(
      lastName,
      VALIDATIONS.ABOUTYOU.LASTNAME
    );
    errorList = { ...errorList, lastNameErrorList };
    if (lastNameErrorList && lastNameErrorList.length > 0) {
      valid = false;
    }

    const dateOfBirthErrorList = checkErrors(
      dateOfBirth,
      VALIDATIONS.ABOUTYOU.DOB
    );
    errorList = { ...errorList, dateOfBirthErrorList };
    if (dateOfBirthErrorList && dateOfBirthErrorList.length > 0) {
      valid = false;
    }

    if (finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.OVATION) {
      const currentAddressStreetNumberErrorList = checkErrors(
        currentAddressStreetNumber,
        VALIDATIONS.ABOUTYOU.STREET_NO
      );
      errorList = { ...errorList, currentAddressStreetNumberErrorList };
      if (
        currentAddressStreetNumberErrorList &&
        currentAddressStreetNumberErrorList.length > 0
      ) {
        valid = false;
      }
      const currentAddressStreetNameErrorList = checkErrors(
        currentAddressStreetName,
        VALIDATIONS.ABOUTYOU.STREET_NAME
      );
      errorList = { ...errorList, currentAddressStreetNameErrorList };
      if (
        currentAddressStreetNumberErrorList &&
        currentAddressStreetNameErrorList.length > 0
      ) {
        valid = false;
      }
    } else {
      const addressErrorList = checkErrors(
        address,
        VALIDATIONS.ABOUTYOU.ADDRESS
      );
      console.log("addressErrorList", addressErrorList);
      errorList = { ...errorList, addressErrorList };
      if (addressErrorList && addressErrorList.length > 0) {
        valid = false;
      }
    }

    const cityErrorList = checkErrors(city, VALIDATIONS.ABOUTYOU.CITY);
    errorList = { ...errorList, cityErrorList };
    if (cityErrorList && cityErrorList.length > 0) {
      valid = false;
    }

    const provinceErrorList = checkErrors(
      province,
      VALIDATIONS.ABOUTYOU.PROVINCE
    );
    errorList = { ...errorList, provinceErrorList };
    if (provinceErrorList && provinceErrorList.length > 0) {
      valid = false;
    }

    const postalCodeErrorList = checkErrors(
      postalCode,
      VALIDATIONS.ABOUTYOU.CA_POSTALCODE
    );
    errorList = { ...errorList, postalCodeErrorList };
    if (postalCodeErrorList && postalCodeErrorList.length > 0) {
      valid = false;
    }

    if (!mailingSameAsCivic) {
      if (finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.OVATION) {
        const mailingStreetNumberErrorList = checkErrors(
          mailingStreetNumber,
          VALIDATIONS.ABOUTYOU.STREET_NO
        );
        errorList = { ...errorList, mailingStreetNumberErrorList };
        if (
          mailingStreetNumberErrorList &&
          mailingStreetNumberErrorList.length > 0
        ) {
          valid = false;
        }

        const mailingStreetNameErrorList = checkErrors(
          mailingStreetName,
          VALIDATIONS.ABOUTYOU.STREET_NAME
        );
        errorList = { ...errorList, mailingStreetNameErrorList };
        if (
          mailingStreetNameErrorList &&
          mailingStreetNameErrorList.length > 0
        ) {
          valid = false;
        }
      } else {
        const mailingAddressLine1ErrorList = checkErrors(
          mailingAddressLine1,
          VALIDATIONS.ABOUTYOU.ADDRESS
        );
        errorList = { ...errorList, mailingAddressLine1ErrorList };
        if (
          mailingAddressLine1ErrorList &&
          mailingAddressLine1ErrorList.length > 0
        ) {
          valid = false;
        }
      }

      const mailingCityErrorList = checkErrors(
        mailingCity,
        VALIDATIONS.ABOUTYOU.CITY
      );
      errorList = { ...errorList, mailingCityErrorList };
      if (mailingCityErrorList && mailingCityErrorList.length > 0) {
        valid = false;
      }

      const mailingProvinceErrorList = checkErrors(
        mailingProvince,
        VALIDATIONS.ABOUTYOU.PROVINCE
      );

      errorList = { ...errorList, mailingProvinceErrorList };
      if (mailingProvinceErrorList && mailingProvinceErrorList.length > 0) {
        valid = false;
      }

      const mailingCountryErrorList = checkErrors(
        mailingCountry,
        VALIDATIONS.ABOUTYOU.COUNTRY
      );
      errorList = { ...errorList, mailingCountryErrorList };
      if (mailingCountryErrorList && mailingCountryErrorList.length > 0) {
        valid = false;
      }

      const postalCodePattern =
        VALIDATIONS.ABOUTYOU[
          `${getSelectedCountry(mailingCountry)}_POSTALCODE`
        ];
      const mailingPostalCodeErrorList = checkErrors(
        mailingPostalCode,
        postalCodePattern
      );
      errorList = { ...errorList, mailingPostalCodeErrorList };
      if (mailingPostalCodeErrorList && mailingPostalCodeErrorList.length > 0) {
        valid = false;
      }
    }

    const mailingSameAsCivicErrorList = checkErrors(
      mailingSameAsCivic,
      VALIDATIONS.ABOUTYOU.ADDRESSCONFIRMATION
    );
    errorList = { ...errorList, mailingSameAsCivicErrorList };
    if (mailingSameAsCivicErrorList && mailingSameAsCivicErrorList.length > 0) {
      valid = false;
    }

    if (Object.keys(errorList).length > 0) {
      console.log("validation", errorList);
      this.setState({ errors: errorList });
    }

    return valid;
  };

  toggleButton = (name, choice) => {
    console.log("toggleButton", name, choice);
    const { errors } = this.state;
    this.setState({
      [name]: choice,
      errors: {
        ...errors,
        [`${name}ErrorList`]: [],
      },
    });
    if (!choice) {
      if (name === "mailingSameAsCivic") {
        this.setState({
          blockAddressEdit2: false,
        });
      } else {
        this.setState({
          blockAddressEdit: false,
        });
        scroller.scrollTo("scroll-to-element", {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
        });
      }
    }
    if (name === STRINGS.ABOUTYOU.PARTFIVE.FIELDNAME.SCANCONFIRMATION) {
      this.setState({ editMode: !choice, allowAddressInfoEdit: !choice });
    }

    // if (name === STRINGS.ABOUTYOU.PARTFIVE.FIELDNAME.MAILINGSAMEASCIVIC) {
    //   this.setState({
    //     mailingAddressLine1: "",
    //     mailingAddressLine2: "",
    //     mailingCity: "",
    //     mailingProvince: "",
    //     mailingPostalCode: "",
    //     mailingCountry: "",
    //     errors: {
    //       ...errors,
    //       mailingAddressLine1ErrorList: [],
    //       mailingCityErrorList: [],
    //       mailingProvinceErrorList: [],
    //       mailingPostalCodeErrorList: [],
    //       mailingCountryErrorList: [],
    //       scanConfirmationErrorList: [],
    //       mailingSameAsCivicErrorList: [],
    //     },
    //   });
    // }
  };

  doFindAddress = (value, event) => {
    const {
      executeAdressSearch,
      addressInstanceKey,
      province,
      postalCode,
      city,
      country,
    } = this.state;
    const { doGetAddress, finInfo } = this.props;
    const eventValue = event?.target.value;
    console.log("doFindAddress", value, eventValue);
    this.setState(
      { selectedAddress: [], address: eventValue, blockAddressEdit: false },
      () => {
        if (value && value.length > 4) {
          const params = {
            InstanceKey: addressInstanceKey,
            AddressLine1: value,
            Country: "Canada",
          };
          const addressSearchResult = [];
          doGetAddress(params, (response) => {
            console.log("doGetAddress", response);
            const addressRes = response.data.Output;
            console.log("doGetAddress", addressRes);
            if (addressRes && addressRes.length > 0 && executeAdressSearch) {
              addressRes.forEach((raw, idx) => {
                if (raw.Status !== "F") {
                  if (raw.Ranges && raw.Ranges.length > 0) {
                    raw.Ranges.forEach((range, idx) => {
                      const address = {
                        label: range.FormattedRangeAddress.substring(
                          0,
                          range.FormattedRangeAddress.indexOf(",")
                        ),
                        AddressLine1: raw.AddressLine1,
                        StateProvince: raw.StateProvince,
                        PostalCode: raw.PostalCode,
                        City: raw.City,
                        ProcessedBy: raw.ProcessedBy,
                        Country: finInfo.country[raw.Country],
                        Unit: "",
                      };
                      if (range.UnitsInfo && range.UnitsInfo.length > 0) {
                        range.UnitsInfo.forEach((unit) => {
                          const addr = unit.FormattedUnitAddress.substring(
                            0,
                            unit.FormattedUnitAddress.indexOf(",")
                          );
                          addressSearchResult.push({
                            ...address,
                            label: unit.FormattedUnitAddress,
                            AddressLine1: addr.startsWith(`${unit.UnitInfo}-`)
                              ? addr.substring(addr.indexOf("-") + 1)
                              : addr,
                            Unit: unit.UnitInfo,
                          });
                        });
                      } else {
                        addressSearchResult.push(address);
                      }
                    });
                  } else {
                    const address = {
                      label: raw.FormattedAddress,
                      AddressLine1: raw.FormattedAddress.substring(
                        0,
                        raw.FormattedAddress.indexOf(",")
                      ),
                      StateProvince: raw.StateProvince,
                      PostalCode: raw.PostalCode,
                      City: raw.City,
                      ProcessedBy: raw.ProcessedBy,
                      Country: finInfo.country[raw.Country],
                      Unit: "",
                    };
                    addressSearchResult.push(address);
                  }
                }
              });
            }
            const address = {
              label: STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS,
              AddressLine1: eventValue,
              StateProvince: province,
              PostalCode: postalCode,
              City: city,
              Country: country,
            };
            addressSearchResult.push(address);
            console.log("addressSearchResult", addressSearchResult);
            this.setState({ addressSearchResult, showSearchResult: true });
          });
        }
      }
    );
  };

  doFindAddressOvation = (value, event) => {
    const {
      executeAdressSearch,
      addressInstanceKey,
      province,
      postalCode,
      city,
      country,
      countriesList,
    } = this.state;
    const { doGetAddress, finInfo } = this.props;
    const eventValue = event?.target.value;
    console.log("doFindAddressOvation", value, eventValue);
    this.setState({ selectedAddress: [], blockAddressEdit: false }, () => {
      if (value && value.length > 4) {
        const params = {
          InstanceKey: addressInstanceKey,
          AddressLine1: value,
          Country: "Canada",
        };
        const addressSearchResult = [];
        doGetAddress(params, (response) => {
          console.log("doGetAddress", response);
          const addressRes = response.data.Output;
          console.log("doGetAddress", addressRes);
          if (addressRes && addressRes.length > 0 && executeAdressSearch) {
            addressRes.forEach((raw, idx) => {
              if (raw.Status !== "F") {
                if (raw.Ranges && raw.Ranges.length > 0) {
                  raw.Ranges.forEach((range, idx) => {
                    const streetNo =
                      range.Range?.indexOf("-") > -1
                        ? range.Range.split("-")[1]
                        : "";
                    const address = {
                      label: range.FormattedRangeAddress,
                      AddressLine1: raw.AddressLine1,
                      StreetNumber: streetNo,
                      StreetName: streetNo
                        ? raw.AddressLine1.substring(streetNo.length)?.trim()
                        : raw.AddressLine1,
                      StateProvince: raw.StateProvince,
                      PostalCode: raw.PostalCode,
                      City: raw.City,
                      ProcessedBy: raw.ProcessedBy,
                      Country: finInfo.country[raw.Country],
                      Unit:
                        range.Range?.indexOf("-") > -1
                          ? range.Range.split("-")[0]
                          : "",
                    };
                    if (range.UnitsInfo && range.UnitsInfo.length > 0) {
                      range.UnitsInfo.forEach((unit) => {
                        addressSearchResult.push({
                          ...address,
                          label: unit.FormattedUnitAddress,
                          Unit: unit.UnitInfo,
                        });
                      });
                    } else {
                      addressSearchResult.push(address);
                    }
                  });
                } else {
                  const address = {
                    label:
                      raw.AddressLine1.indexOf(`${raw.HouseNumber} `) === -1
                        ? `${raw.AddressLine1}, ${raw.City}, ${raw.StateProvince}, ${raw.PostalCode}`
                        : `${raw.HouseNumber} ${raw.AddressLine1}, ${raw.City}, ${raw.StateProvince}, ${raw.PostalCode}`,
                    AddressLine1: raw.AddressLine1,
                    StreetNumber: raw.HouseNumber,
                    StreetName:
                      raw.AddressLine1.indexOf(`${raw.HouseNumber} `) === -1
                        ? raw.AddressLine1
                        : raw.AddressLine1.substring(
                            raw.HouseNumber.length
                          )?.trim(),
                    StateProvince: raw.StateProvince,
                    PostalCode: raw.PostalCode,
                    City: raw.City,
                    ProcessedBy: raw.ProcessedBy,
                    Country: finInfo.country[raw.Country],
                    Unit: "",
                  };
                  addressSearchResult.push(address);
                }
              }
            });
          }
          const address = {
            label: STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS,
            AddressLine1: eventValue,
            StateProvince: province,
            PostalCode: postalCode,
            City: city,
            Country: country,
          };
          addressSearchResult.push(address);
          console.log("addressSearchResult", addressSearchResult);
          this.setState({ addressSearchResult, showSearchResult: true });
        });
      }
    });
  };

  doFindAddress2Ovation = (value, event) => {
    const {
      executeAdressSearch,
      addressInstanceKey,
      province,
      postalCode,
      city,
      country,
      countriesList,
    } = this.state;
    const { doGetAddress, finInfo } = this.props;
    const eventValue = event?.target.value;
    console.log("doFindAddress2Ovation", value, eventValue);
    this.setState({ selectedAddress2: [], blockAddressEdit2: false }, () => {
      if (value && value.length > 4) {
        const params = {
          InstanceKey: addressInstanceKey,
          AddressLine1: value,
          Country: "Canada",
        };
        const addressSearchResult2 = [];
        doGetAddress(params, (response) => {
          console.log("doGetAddress", response);
          const addressRes = response.data.Output;
          console.log("doGetAddress", addressRes);
          if (addressRes && addressRes.length > 0 && executeAdressSearch) {
            addressRes.forEach((raw, idx) => {
              if (raw.Status !== "F") {
                if (raw.Ranges && raw.Ranges.length > 0) {
                  raw.Ranges.forEach((range, idx) => {
                    const streetNo =
                      range.Range?.indexOf("-") > -1
                        ? range.Range.split("-")[1]
                        : "";
                    const address = {
                      label: range.FormattedRangeAddress,
                      AddressLine1: raw.AddressLine1,
                      StreetNumber: streetNo,
                      StreetName: streetNo
                        ? raw.AddressLine1.substring(streetNo.length)?.trim()
                        : raw.AddressLine1,
                      StateProvince: raw.StateProvince,
                      PostalCode: raw.PostalCode,
                      City: raw.City,
                      ProcessedBy: raw.ProcessedBy,
                      Country: finInfo.country[raw.Country],
                      Unit:
                        range.Range?.indexOf("-") > -1
                          ? range.Range.split("-")[0]
                          : "",
                    };
                    if (range.UnitsInfo && range.UnitsInfo.length > 0) {
                      range.UnitsInfo.forEach((unit) => {
                        addressSearchResult2.push({
                          ...address,
                          label: unit.FormattedUnitAddress,
                          Unit: unit.UnitInfo,
                        });
                      });
                    } else {
                      addressSearchResult2.push(address);
                    }
                  });
                } else {
                  const address = {
                    label:
                      raw.AddressLine1.indexOf(`${raw.HouseNumber} `) === -1
                        ? `${raw.AddressLine1}, ${raw.City}, ${raw.StateProvince}, ${raw.PostalCode}`
                        : `${raw.HouseNumber} ${raw.AddressLine1}, ${raw.City}, ${raw.StateProvince}, ${raw.PostalCode}`,
                    AddressLine1: raw.AddressLine1,
                    StreetNumber: raw.HouseNumber,
                    StreetName:
                      raw.AddressLine1.indexOf(`${raw.HouseNumber} `) === -1
                        ? raw.AddressLine1
                        : raw.AddressLine1.substring(
                            raw.HouseNumber.length
                          )?.trim(),
                    StateProvince: raw.StateProvince,
                    PostalCode: raw.PostalCode,
                    City: raw.City,
                    ProcessedBy: raw.ProcessedBy,
                    Country: finInfo.country[raw.Country],
                    Unit: "",
                  };
                  addressSearchResult2.push(address);
                }
              }
            });
          }
          const address = {
            label: STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS,
            AddressLine1: eventValue,
            StateProvince: province,
            PostalCode: postalCode,
            City: city,
            Country: country,
          };
          addressSearchResult2.push(address);
          console.log("addressSearchResult2", addressSearchResult2);
          this.setState({ addressSearchResult2, showSearchResult2: true });
        });
      }
    });
  };

  handleChangeStreet = (event) => {
    console.log("handleChangeStreet", event);
    this.setState({ address: event.target.value });
  };

  getProvinceCode = (code) => {
    const { provinceList } = this.props;
    const province = provinceList.find((item) => item.code === code);
    console.log("getProvinceCode", province);
    if (province) {
      return province.code;
    }
    return "";
  };

  handleChangeAddress = (value) => {
    const { errors } = this.state;
    console.log("handleChangeAddress", value);
    if (value && value.length > 0) {
      if (value[0].label === STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS) {
        this.searchField.current.clear();
      }
      if (value[0].AddressLine1) {
        const address = {
          label: value[0].AddressLine1,
          AddressLine1: value[0].AddressLine1,
          StateProvince: value[0].StateProvince,
          PostalCode: value[0].PostalCode,
          City: value[0].City,
          ProcessedBy: value[0].ProcessedBy,
          Country: value[0].Country,
          Unit: value[0].Unit,
        };
        const selectedAddress = [];
        selectedAddress.push(address);
        this.setState({
          selectedAddress,
          city: value[0].City,
          postalCode: value[0].PostalCode,
          province: this.getProvinceCode(value[0].StateProvince),
          apartment: value[0].Unit,
          address: value[0].AddressLine1,
          showSearchResult: false,
          blockAddressEdit: false,
          errors: {
            ...errors,
            cityErrorList: [],
            postalCodeErrorList: [],
            provinceErrorList: [],
            apartmentErrorList: [],
            addressErrorList: [],
          },
        });
      } else {
        this.setState({
          blockAddressEdit: false,
          showSearchResult: false,
          executeAdressSearch: false,
        });
      }
    }
  };

  handleChangeAddressOvation = (value) => {
    console.log("handleChangeAddressOvation", value);
    const { errors } = this.state;
    if (value && value.length > 0) {
      if (value[0].label === STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS) {
        this.searchField.current.clear();
      }
      if (value[0].AddressLine1) {
        const address = {
          label: value[0].label,
          AddressLine1: value[0].AddressLine1,
          CurrentAddressStreetNumber: value[0].StreetNumber,
          CurrentAddressStreetName: value[0].StreetName,
          StateProvince: value[0].StateProvince,
          PostalCode: value[0].PostalCode,
          City: value[0].City,
          ProcessedBy: value[0].ProcessedBy,
          Country: value[0].Country,
          Unit: value[0].Unit,
        };
        const selectedAddress = [];
        selectedAddress.push(address);
        this.setState({
          selectedAddress,
          city: value[0].City,
          postalCode: value[0].PostalCode,
          province: this.getProvinceCode(value[0].StateProvince),
          apartment: value[0].Unit,
          address: value[0].AddressLine1,
          currentAddressStreetNumber: value[0].StreetNumber,
          currentAddressStreetName: value[0].StreetName,
          showSearchResult: false,
          blockAddressEdit: false,
          errors: {
            ...errors,
            cityErrorList: [],
            postalCodeErrorList: [],
            provinceErrorList: [],
            apartmentErrorList: [],
            addressErrorList: [],
            currentAddressStreetNumberErrorList: [],
            currentAddressStreetNameErrorList: [],
          },
        });
      } else {
        this.setState({
          blockAddressEdit: false,
          showSearchResult: false,
          executeAdressSearch: false,
        });
      }
    }
  };

  doFindAddress2 = (value, event) => {
    const {
      executeAdressSearch2,
      addressInstanceKey,
      mailingProvince,
      mailingPostalCode,
      mailingCity,
      mailingCountry,
      countriesList,
    } = this.state;
    const { doGetAddress, finInfo } = this.props;
    const eventValue = event?.target.value;
    console.log("doFindAddress2", value, eventValue);
    this.setState(
      {
        selectedAddress2: [],
        mailingAddressLine1: eventValue,
        blockAddressEdit2: false,
      },
      () => {
        if (value && value.length > 4) {
          const params = {
            InstanceKey: addressInstanceKey,
            AddressLine1: value,
            Country: "Canada",
          };
          const addressSearchResult2 = [];
          doGetAddress(params, (response) => {
            console.log("doGetAddress", response);
            const addressRes = response.data.Output;
            console.log("doGetAddress", addressRes);
            if (addressRes && addressRes.length > 0 && executeAdressSearch2) {
              addressRes.forEach((raw, idx) => {
                if (raw.Status !== "F") {
                  const address = {
                    label: `${raw.AddressLine1}, ${raw.City}, ${raw.StateProvince}, ${raw.PostalCode}`,
                    AddressLine1: raw.AddressLine1,
                    StateProvince: raw.StateProvince,
                    PostalCode: raw.PostalCode,
                    City: raw.City,
                    ProcessedBy: raw.ProcessedBy,
                    Country: finInfo.country[raw.Country],
                  };
                  addressSearchResult2.push(address);
                }
              });
              const address = {
                label: STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS,
                AddressLine1: eventValue,
                StateProvince: mailingProvince,
                PostalCode: mailingPostalCode,
                City: mailingCity,
                Country: mailingCountry,
              };
              addressSearchResult2.push(address);
              console.log("addressSearchResult", addressSearchResult2);
              this.setState({ addressSearchResult2, showSearchResult2: true });
            }
          });
        }
      }
    );
  };

  handleChangeAddress2 = (value) => {
    console.log("handleChangeAddress2", value);
    const { errors } = this.state;
    if (value && value.length > 0) {
      if (value[0].label === STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS) {
        this.searchField2.current.clear();
      }
      if (value[0].AddressLine1) {
        const address = {
          label: value[0].AddressLine1,
          AddressLine1: value[0].AddressLine1,
          StateProvince: value[0].StateProvince,
          PostalCode: value[0].PostalCode,
          City: value[0].City,
          ProcessedBy: value[0].ProcessedBy,
          Country: value[0].Country,
        };
        const selectedAddress2 = [];
        selectedAddress2.push(address);
        this.setState({
          selectedAddress2,
          mailingCity: value[0].City,
          mailingPostalCode: value[0].PostalCode,
          mailingProvince: this.getProvinceCode(value[0].StateProvince),
          mailingAddressLine1: value[0].AddressLine1,
          mailingCountry: value[0].Country,
          showSearchResult2: false,
          blockAddressEdit2: false,
          errors: {
            ...errors,
            mailingCityErrorList: [],
            mailingPostalCodeErrorList: [],
            mailingProvinceErrorList: [],
            mailingAddressLine1ErrorList: [],
            mailingCountryErrorList: [],
          },
        });
      } else {
        this.setState({
          blockAddressEdit2: false,
          showSearchResult2: false,
          executeAdressSearch2: false,
        });
      }
    }
  };

  handleChangeAddress2Ovation = (value) => {
    console.log("handleChangeAddress2Ovation", value);
    const { errors } = this.state;
    if (value && value.length > 0) {
      if (value[0].label === STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS) {
        this.searchField2.current.clear();
      }
      if (value[0].AddressLine1) {
        const address = {
          label: value[0].label,
          AddressLine1: value[0].AddressLine1,
          StateProvince: value[0].StateProvince,
          PostalCode: value[0].PostalCode,
          City: value[0].City,
          ProcessedBy: value[0].ProcessedBy,
          Country: value[0].Country,
          StreetNumber: value[0].StreetNumber,
          StreetName: value[0].StreetName,
        };
        const selectedAddress2 = [];
        selectedAddress2.push(address);
        this.setState({
          selectedAddress2,
          mailingCity: value[0].City,
          mailingPostalCode: value[0].PostalCode,
          mailingProvince: this.getProvinceCode(value[0].StateProvince),
          mailingAddressLine1: value[0].AddressLine1,
          mailingCountry: value[0].Country,
          mailingStreetNumber: value[0].StreetNumber,
          mailingStreetName: value[0].StreetName,
          showSearchResult2: false,
          blockAddressEdit2: false,
          errors: {
            ...errors,
            mailingCityErrorList: [],
            mailingPostalCodeErrorList: [],
            mailingProvinceErrorList: [],
            mailingAddressLine1ErrorList: [],
            mailingCountryErrorList: [],
            mailingStreetNumberErrorList: [],
            mailingStreetNameErrorList: [],
          },
        });
      } else {
        this.setState({
          blockAddressEdit2: false,
          showSearchResult2: false,
          executeAdressSearch2: false,
        });
      }
    }
  };

  handleAddressMessageClick = () => {
    this.setState({
      addressValidationMessage: "",
      showAddressMessage: false,
    });
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_KYC);
  };

  render() {
    const {
      userInfo,
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      address,
      apartment,
      city,
      province,
      unitType,
      streetType,
      direction,
      postalCode,
      currentAddressStreetNumber,
      currentAddressStreetName,
      mailingAddressLine1,
      mailingApartment,
      mailingCity,
      mailingProvince,
      mailingPostalCode,
      mailingCountry,
      mailingUnitType,
      mailingStreetType,
      mailingStreetDir,
      mailingStreetNumber,
      mailingStreetName,
      scanConfirmation,
      mailingSameAsCivic,
      errors,
      editMode,
      countriesList,
      startDate,
      endDate,
      minDate,
      maxDate,
      years,
      months,
      selectedAddress,
      addressSearchResult,
      showSearchResult,
      blockAddressEdit,
      selectedAddress2,
      addressSearchResult2,
      showSearchResult2,
      blockAddressEdit2,
      showModal,
      modal,
      allowPersonalInfoEdit,
      allowAddressInfoEdit,
      showAddressMessage,
    } = this.state;
    const {
      steps,
      activeStepID,
      selfieImage,
      productList,
      vaultProductList,
      finInfo,
      instntTxnId,
      getActiveProduct,
      inSessionJointApplicant,
      provinceList,
      applicantData,
      handleBack,
    } = this.props;

    console.log(steps, activeStepID);

    const countryCode = getSelectedCountry(mailingCountry);

    const mailingPostalCodePattern =
      AppConstants.ABOUT_YOU[`${countryCode}_POSTALMASK`];
    let selfie = null;
    const dob = dateOfBirth
      ? parse(dateOfBirth, "yyyy-MM-dd", new Date())
      : null;
    console.log("render", editMode, allowPersonalInfoEdit, city);
    const activeProduct = getActiveProduct(vaultProductList);
    const product = getProduct(productList, vaultProductList);

    const userInformation = (
      <>
        <div className="[ col-12 col-lg-3 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMELABEL}
            </div>
            <>
              <input
                type="text"
                name="firstName"
                className="[ form-control ]"
                placeholder={
                  STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMELABEL
                }
                value={firstName}
                onChange={this.onChangeHandler}
                readOnly={!editMode || !allowPersonalInfoEdit}
                ref={(input) => {
                  this.textInput = input;
                }}
              />
              <div className="[ error-msg ]">
                {errors?.firstNameErrorList[0]}
              </div>
            </>
          </div>
        </div>

        <div className="[  col-12 col-lg-3 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.MIDDLENAMELABEL}
            </div>
            <>
              <input
                type="text"
                name="middleName"
                className="[ form-control ]"
                placeholder={
                  STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.MIDDLENAMELABEL
                }
                value={middleName}
                onChange={this.onChangeHandler}
                readOnly={!editMode || !allowPersonalInfoEdit}
              />
              <div className="[ error-msg ]" />
            </>
          </div>
        </div>

        <div className="[  col-12 col-lg-3 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.LASTNAMELABEL}
            </div>
            <>
              <input
                type="text "
                name="lastName"
                className="[ form-control ]"
                placeholder={STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.LASTNAMELABEL}
                value={lastName}
                onChange={this.onChangeHandler}
                readOnly={!editMode || !allowPersonalInfoEdit}
              />
              <div className="[ error-msg ]">
                {errors?.lastNameErrorList[0]}
              </div>
            </>
          </div>
        </div>

        <div className="[  col-12 col-lg-3 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.DOBLABEL}
            </div>
            <>
              <DatePicker
                className="[ datepicker ]"
                dateFormat="yyyy-MM-dd"
                placeholderText={`${STRINGS.DOBPLACEHOLDER}`}
                selected={dob}
                onChange={(date) => this.setDOB(date)}
                minDate={startDate}
                maxDate={endDate}
                readOnly={!editMode || !allowPersonalInfoEdit}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div>
                    <button
                      type="button"
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {"<"}
                    </button>
                    <select
                      value={getYear(date)}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      value={months[getMonth(date)]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <button
                      type="button"
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {">"}
                    </button>
                  </div>
                )}
              />
              <div className="[ error-msg ]">
                {errors?.dateOfBirthErrorList[0]}
              </div>
            </>
          </div>
        </div>
      </>
    );

    const selectList = provinceList.map((elem) => (
      <option
        value={elem.code}
        key={elem.code}
        className="[ dropdown__option ]"
      >
        {elem.description}
      </option>
    ));

    const selectElement = (
      <select
        name="province"
        value={province}
        className={
          errors?.provinceErrorList.length > 0
            ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
            : "[ form-control ] [ select__dropdown ]"
        }
        onChange={this.onChangeHandler}
        disabled={blockAddressEdit || !allowAddressInfoEdit}
        readOnly={!allowAddressInfoEdit}
      >
        <option value="" className="[ default ] [ dropdown__option ]">
          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
        </option>
        {selectList}
      </select>
    );

    const mailingSelectElement = (
      <select
        name="mailingProvince"
        value={mailingProvince}
        className={
          errors?.mailingProvinceErrorList.length > 0
            ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
            : "[ form-control ] [ select__dropdown ]"
        }
        onChange={this.onChangeHandler}
        disabled={
          blockAddressEdit2 && !(allowAddressInfoEdit || !mailingSameAsCivic)
        }
        readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
      >
        <option value="" className="[ default ] [ dropdown__option ]">
          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
        </option>
        {selectList}
      </select>
    );

    const countryElem = countriesList.map((country) => (
      <option
        value={country.code}
        key={country.code}
        className="[ dropdown__option ]"
      >
        {country.description}
      </option>
    ));

    const countrySelectElem = (
      <select
        name="mailingCountry"
        value={mailingCountry}
        className={
          errors?.mailingCountryErrorList.length > 0
            ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
            : "[ form-control ] [ select__dropdown ]"
        }
        onChange={this.onChangeHandler}
        disabled={
          blockAddressEdit2 && !(allowAddressInfoEdit || !mailingSameAsCivic)
        }
        readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
      >
        <option value="" className="[ default ] [ dropdown__option ]">
          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.COUNTRY}
        </option>
        {countryElem}
      </select>
    );

    const thankYouCard = (
      <div className="[ thank-you-container ]">
        <div className="[ row ]">
          <div className="[ col-12 ]" />
          <div className="[ col-12 ] [ info-tile__title ]">
            {STRINGS.ABOUTYOU.PARTFIVE.THANKYOUTITLE + firstName}
          </div>
        </div>
        <div className="[ row ]">
          <div className="[ col-12 ] [ info-tile__description ]">
            {STRINGS.ABOUTYOU.PARTFIVE.THANKYOUTEXT}
            {product.productName}
          </div>
        </div>
        <div className="[ row ]">
          <div className="[ col-12 ] [ info-tile__description ]">
            {STRINGS.ABOUTYOU.PARTFIVE.REVIEWREQ}
          </div>
        </div>
      </div>
    );

    const addressInputGroup = (
      <div className="[ row ]">
        <div className="col-md-12">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS0}
            </div>
            <>
              <Typeahead
                ref={this.searchField}
                id="basic-typeahead-single"
                className="[ address-suggestions ]"
                clearButton={false}
                onChange={this.handleChangeAddress}
                onInputChange={this.doFindAddress}
                options={addressSearchResult}
                labelKey="label"
                placeholder={
                  STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS_PLACEHOLDER
                }
                selected={selectedAddress}
                emptyLabel={STRINGS.ADDRESS_SEARCH.NO_RECORDS_FOUND}
                open={showSearchResult}
                filterBy={() => true}
                inputProps={{
                  readOnly: !allowAddressInfoEdit,
                }}
                onBlur={() =>
                  this.setState({
                    showSearchResult: false,
                  })
                }
              />
              <div>
                <small>
                  {STRINGS.ADDRESS_SEARCH.SEARCH_MESSAGE}{" "}
                  {STRINGS.ADDRESS_SEARCH.RESIDENTIAL_ADDRESS_REQ}
                </small>
              </div>
              <div className="[ error-msg ]">{errors?.addressErrorList[0]}</div>
            </>
          </div>
        </div>

        <div className="[ col-md-4 ]">
          <div className="[ form-label ]">
            {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_LABEL}
          </div>
          <>
            <input
              maxLength={AppConstants.ABOUT_YOU.ADDRESS_LIMIT}
              type="text"
              name="apartment"
              className="[ form-control ]"
              placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.IF_APPLICABLE}
              value={apartment}
              onChange={this.onChangeHandler}
              readOnly={!allowAddressInfoEdit}
              disabled={blockAddressEdit}
            />
            <div className="[ error-msg ]">&nbsp;</div>
          </>
        </div>

        <div className="[ col-12 col-md-6 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
            </div>
            <>
              <input
                maxLength={AppConstants.ABOUT_YOU.ADDRESS_LIMIT}
                type="text"
                name="city"
                className={
                  errors?.cityErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ]"
                }
                placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                value={city}
                onChange={this.onChangeHandler}
                readOnly={!allowAddressInfoEdit}
                disabled={blockAddressEdit}
              />
              <div className="[ error-msg ]">{errors?.cityErrorList[0]}</div>
            </>
          </div>
        </div>

        <div className="[ col-12 col-md-3 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
            </div>
            {/* <div className="[ dropdown ]"> */}
            {selectElement}
            {/* </div> */}
            <div className="[ error-msg ]">{errors?.provinceErrorList[0]}</div>
          </div>
        </div>

        <div className="[ col-12 col-md-3 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
            </div>
            <>
              <MaskedInput
                mask={AppConstants.ABOUT_YOU.CA_POSTALMASK}
                className={
                  errors?.postalCodeErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ] [ postalCode ]"
                    : "[ form-control ] [ postalCode ]"
                }
                placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
                guide={false}
                id="postalCode"
                name="postalCode"
                value={postalCode}
                onChange={this.onChangeHandler}
                readOnly={!allowAddressInfoEdit}
                disabled={blockAddressEdit}
              />
            </>
            <div className="[ error-msg ]">
              {errors?.postalCodeErrorList[0]}
            </div>
          </div>
        </div>
      </div>
    );

    const ovationAddressGroup = (
      <div>
        <div className="[ row ]">
          <div className="[ col-md-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_LABEL}
            </div>
            <>
              <input
                type="text"
                name="apartment"
                className="[ form-control ]"
                value={apartment}
                onChange={this.onChangeHandler}
                readOnly={!allowAddressInfoEdit}
                disabled={blockAddressEdit}
              />
              <div className="[ error-msg ]">&nbsp;</div>
            </>
          </div>
          <div className="[ col-md-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_TYPE}
            </div>
            <>
              <select
                name="unitType"
                value={unitType}
                className={
                  errors?.provinceErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                    : "[ form-control ] [ select__dropdown ]"
                }
                onChange={this.onChangeHandler}
                disabled={blockAddressEdit || !allowAddressInfoEdit}
                readOnly={!allowAddressInfoEdit}
              >
                {unitTypeList.map((e) => {
                  return (
                    <option value={e} className="[ dropdown__option ]">
                      {e}
                    </option>
                  );
                })}
              </select>
              <div className="[ error-msg ]">&nbsp;</div>
            </>
          </div>
        </div>
        <div className="[ row ]">
          <div className="col-md-2">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.STREET_NO}
            </div>
            <>
              <input
                type="text"
                name="currentAddressStreetNumber"
                className={
                  errors?.currentAddressStreetNumberErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ]"
                }
                placeholder=""
                value={currentAddressStreetNumber}
                onChange={this.onChangeHandler}
                readOnly={!allowAddressInfoEdit}
                disabled={blockAddressEdit}
              />
              <div className="[ error-msg ]">
                {errors?.currentAddressStreetNumberErrorList[0]}
              </div>
            </>
          </div>
          <div className="col-md-4">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.STREET_NAME}
            </div>
            <>
              <input
                type="text"
                name="currentAddressStreetName"
                className={
                  errors?.currentAddressStreetNameErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ]"
                }
                placeholder=""
                value={currentAddressStreetName}
                onChange={this.onChangeHandler}
                readOnly={!allowAddressInfoEdit}
                disabled={blockAddressEdit}
              />
              <div className="[ error-msg ]">
                {errors?.currentAddressStreetNameErrorList[0]}
              </div>
            </>
          </div>
          <div className="[ col-md-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.STREET_TYPE}
            </div>
            <>
              <select
                name="streetType"
                value={streetType}
                className="[ form-control ] [ select__dropdown ]"
                onChange={this.onChangeHandler}
                disabled={blockAddressEdit || !allowAddressInfoEdit}
                readOnly={!allowAddressInfoEdit}
              >
                {streetTypeList.map((e) => {
                  return (
                    <option value={e} className="[ dropdown__option ]">
                      {e}
                    </option>
                  );
                })}
              </select>
              <div className="[ error-msg ]">&nbsp;</div>
            </>
          </div>
          <div className="[ col-md-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.DIRECTION}
            </div>
            <>
              <select
                name="direction"
                value={direction}
                className="[ form-control ] [ select__dropdown ]"
                onChange={this.onChangeHandler}
                disabled={blockAddressEdit || !allowAddressInfoEdit}
                readOnly={!allowAddressInfoEdit}
              >
                {directionList.map((e) => {
                  return (
                    <option value={e} className="[ dropdown__option ]">
                      {e}
                    </option>
                  );
                })}
              </select>
              <div className="[ error-msg ]">&nbsp;</div>
            </>
          </div>
        </div>
        <div className="[ row ]">
          <div className="[ col-12 col-md-6 ]">
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
              </div>
              <>
                <input
                  type="text"
                  name="city"
                  className={
                    errors?.cityErrorList.length > 0
                      ? "[ form-control ] [ invalid-input ]"
                      : "[ form-control ]"
                  }
                  placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                  value={city}
                  onChange={this.onChangeHandler}
                  readOnly={!allowAddressInfoEdit}
                  disabled={blockAddressEdit}
                />
                <div className="[ error-msg ]">{errors?.cityErrorList[0]}</div>
              </>
            </div>
          </div>

          <div className="[ col-12 col-md-3 ]">
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
              </div>
              {/* <div className="[ dropdown ]"> */}
              {selectElement}
              {/* </div> */}
              <div className="[ error-msg ]">
                {errors?.provinceErrorList[0]}
              </div>
            </div>
          </div>

          <div className="[ col-12 col-md-3 ]">
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
              </div>
              <>
                <MaskedInput
                  mask={AppConstants.ABOUT_YOU.CA_POSTALMASK}
                  className={
                    errors?.postalCodeErrorList.length > 0
                      ? "[ form-control ] [ invalid-input ] [ postalCode ]"
                      : "[ form-control ] [ postalCode ]"
                  }
                  placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
                  guide={false}
                  id="postalCode"
                  name="postalCode"
                  value={postalCode}
                  onChange={this.onChangeHandler}
                  readOnly={!allowAddressInfoEdit}
                  disabled={blockAddressEdit}
                />
              </>
              <div className="[ error-msg ]">
                {errors?.postalCodeErrorList[0]}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const actionComponent = (
      <div className="[ existing-client-container ]">
        <div className="[ form-title-existing ]">
          {STRINGS.BUSINESS_KYC.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ row ] [ d-xl-none ] [ mb-3 ]">
            <div className="[ col-12 col-lg-6 ]">
              <div className="[ selfie-container ] ">{selfie}</div>
            </div>
            <div className="[ col-12 col-lg-6 ]">
              {selfieImage ? thankYouCard : null}
            </div>
            <Element name="scroll-to-element" />
          </div>
          <div className="[ divider ] [ d-xl-none ]" />
          <div className="[ form-subtitle ]">
            {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.PERSONALSECTION}
          </div>
          {firstName ? (
            <>
              <div className="[ row ]">{userInformation}</div>
            </>
          ) : (
            <div className="[ row ]">{userInformation}</div>
          )}

          <div className="[ divider ] [ d-xl-none ]" />
          {finInfo.coreBankingType ===
            AppConstants.CORE_BANKING_TYPE.OVATION && (
            <>
              <div className="[ form-subtitle ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.SEARCH_ADDRESS}
              </div>
              <div className="[ row ]">
                <div className="col-md-12">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS0}
                    </div>
                    <>
                      <Typeahead
                        ref={this.searchField}
                        id="basic-typeahead-single"
                        className="[ address-suggestions ]"
                        clearButton={false}
                        onChange={this.handleChangeAddressOvation}
                        onInputChange={this.doFindAddressOvation}
                        options={addressSearchResult}
                        labelKey="label"
                        emptyLabel={STRINGS.ADDRESS_SEARCH.NO_RECORDS_FOUND}
                        open={showSearchResult}
                        filterBy={() => true}
                        inputProps={{
                          readOnly: !allowAddressInfoEdit,
                        }}
                        onBlur={() =>
                          this.setState({
                            showSearchResult: false,
                          })
                        }
                      />
                      <div>
                        <small>
                          {STRINGS.ADDRESS_SEARCH.SEARCH_MESSAGE}{" "}
                          {STRINGS.ADDRESS_SEARCH.RESIDENTIAL_ADDRESS_REQ}
                        </small>
                      </div>
                    </>
                  </div>
                </div>
              </div>
              <div className="[ form-subtitle ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.EDIT_ADDRESS}
              </div>
              {ovationAddressGroup}
            </>
          )}
          {finInfo.coreBankingType !==
            AppConstants.CORE_BANKING_TYPE.OVATION && (
            <>
              <div className="[ form-subtitle ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESSSECTION}
              </div>
              {addressInputGroup}
            </>
          )}

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSCONFIRMQUESTION}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    mailingSameAsCivic !== undefined && mailingSameAsCivic
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={(event) =>
                    this.toggleButton(
                      STRINGS.ABOUTYOU.PARTFIVE.FIELDNAME.MAILINGSAMEASCIVIC,
                      true,
                      event
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    mailingSameAsCivic !== undefined && !mailingSameAsCivic
                      ? "[ btn custom-radio-selected ] "
                      : "[ btn custom-radio-unselected ] "
                  }
                  onClick={(event) =>
                    this.toggleButton(
                      STRINGS.ABOUTYOU.PARTFIVE.FIELDNAME.MAILINGSAMEASCIVIC,
                      false,
                      event
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>
            <div className="[ error-msg ]">
              {errors?.mailingSameAsCivicErrorList[0]}
            </div>
          </div>

          {finInfo.coreBankingType !== AppConstants.CORE_BANKING_TYPE.OVATION &&
          mailingSameAsCivic !== undefined &&
          !mailingSameAsCivic ? (
            <div className="[ drawer-content-container mb-3 ]">
              <Element name="scroll-to-mailing" />
              <div className="[ info-title ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.MAILINGSECTION}
              </div>

              <div className="[ row ]">
                <div className="[ col-md-8 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS0}
                  </div>
                  <Typeahead
                    ref={this.searchField2}
                    id="mailingAddressLine1"
                    className="[ address-suggestions ]"
                    clearButton={false}
                    onChange={this.handleChangeAddress2}
                    onInputChange={this.doFindAddress2}
                    options={addressSearchResult2}
                    labelKey="label"
                    placeholder={
                      STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS_PLACEHOLDER
                    }
                    selected={selectedAddress2}
                    emptyLabel={STRINGS.ADDRESS_SEARCH.NO_RECORDS_FOUND}
                    open={showSearchResult2}
                    filterBy={() => true}
                    inputProps={{
                      readOnly: !(allowAddressInfoEdit || !mailingSameAsCivic),
                    }}
                    onBlur={() =>
                      this.setState({
                        showSearchResult2: false,
                      })
                    }
                  />
                  <div>
                    <small>{STRINGS.ADDRESS_SEARCH.SEARCH_MESSAGE}</small>
                  </div>
                  <div className="[ error-msg ]">
                    {errors?.mailingAddressLine1ErrorList[0]}
                  </div>
                </div>

                <div className="[ col-md-4 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_LABEL}
                  </div>
                  <>
                    <input
                      maxLength={AppConstants.ABOUT_YOU.ADDRESS_LIMIT}
                      type="text"
                      name="mailingApartment"
                      className="[ form-control ]"
                      placeholder={
                        STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.IF_APPLICABLE
                      }
                      value={mailingApartment}
                      onChange={this.onChangeHandler}
                      disabled={
                        blockAddressEdit2 &&
                        !(allowAddressInfoEdit || !mailingSameAsCivic)
                      }
                      readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
                    />
                  </>
                  <div className="[ error-msg ]" />
                </div>

                <div className="[ col-12 col-md-6 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                    </div>
                    <>
                      <input
                        maxLength={AppConstants.ABOUT_YOU.ADDRESS_LIMIT}
                        type="text"
                        name="mailingCity"
                        className={
                          errors?.mailingCityErrorList.length > 0
                            ? "[ form-control ] [ invalid-input ]"
                            : "[ form-control ]"
                        }
                        placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                        value={mailingCity}
                        onChange={this.onChangeHandler}
                        disabled={
                          blockAddressEdit2 &&
                          !(allowAddressInfoEdit || !mailingSameAsCivic)
                        }
                        readOnly={
                          !(allowAddressInfoEdit || !mailingSameAsCivic)
                        }
                      />
                    </>
                    <div className="[ error-msg ]">
                      {errors?.mailingCityErrorList[0]}
                    </div>
                  </div>
                </div>

                <div className="[ col-12 col-md-6 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
                    </div>
                    {/* <div className="[ dropdown ]"> */}
                    {mailingSelectElement}
                    {/* </div> */}
                    <div className="[ error-msg ]">
                      {errors?.mailingProvinceErrorList[0]}
                    </div>
                  </div>
                </div>

                <div className="[ col-12 col-md-6 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.COUNTRY}
                    </div>
                    {/* <div className="[ dropdown ]"> */}
                    {countrySelectElem}
                    {/* </div> */}
                    <div className="[ error-msg ]">
                      {errors?.mailingCountryErrorList[0]}
                    </div>
                  </div>
                </div>

                <div className="[ col-12 col-md-6 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
                    </div>
                    <>
                      <MaskedInput
                        mask={mailingPostalCodePattern}
                        className={
                          errors?.mailingPostalCodeErrorList.length > 0
                            ? "[ form-control ] [ invalid-input ] [ postalCode ]"
                            : "[ form-control ] [ postalCode ]"
                        }
                        placeholder={
                          STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE
                        }
                        guide={false}
                        id="mailingPostalCode"
                        name="mailingPostalCode"
                        value={mailingPostalCode}
                        onChange={this.onChangeHandler}
                        disabled={
                          blockAddressEdit2 &&
                          !(allowAddressInfoEdit || !mailingSameAsCivic)
                        }
                        readOnly={
                          !(allowAddressInfoEdit || !mailingSameAsCivic)
                        }
                      />
                    </>
                    <div className="[ error-msg ]">
                      {errors?.mailingPostalCodeErrorList[0]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.OVATION &&
          mailingSameAsCivic !== "" &&
          !mailingSameAsCivic ? (
            <div className="[ drawer-content-container mb-3 ]">
              <div className="[ info-title ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.MAILINGSECTION}
              </div>
              <div className="[ row ]">
                <div className="col-md-12">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS0}
                    </div>
                    <div>
                      <Typeahead
                        ref={this.searchField2}
                        id="mailingSearchField"
                        className="[ address-suggestions ]"
                        clearButton={false}
                        onChange={this.handleChangeAddress2Ovation}
                        onInputChange={this.doFindAddress2Ovation}
                        options={addressSearchResult2}
                        labelKey="label"
                        emptyLabel={STRINGS.ADDRESS_SEARCH.NO_RECORDS_FOUND}
                        open={showSearchResult2}
                        filterBy={() => true}
                        inputProps={{
                          readOnly: !(
                            allowAddressInfoEdit || !mailingSameAsCivic
                          ),
                        }}
                        onBlur={() =>
                          this.setState({
                            showSearchResult2: false,
                          })
                        }
                      />
                      <div>
                        <small>
                          {STRINGS.ADDRESS_SEARCH.SEARCH_MESSAGE}{" "}
                          {STRINGS.ADDRESS_SEARCH.RESIDENTIAL_ADDRESS_REQ}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="[ row ]">
                <div className="[ col-md-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_LABEL}
                  </div>
                  <>
                    <input
                      type="text"
                      name="mailingApartment"
                      className="[ form-control ]"
                      placeholder={
                        STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.IF_APPLICABLE
                      }
                      value={mailingApartment}
                      onChange={this.onChangeHandler}
                      disabled={
                        blockAddressEdit2 &&
                        !(allowAddressInfoEdit || !mailingSameAsCivic)
                      }
                      readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
                    />
                  </>
                  <div className="[ error-msg ]" />
                </div>
                <div className="[ col-md-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_TYPE}
                  </div>
                  <>
                    <select
                      name="mailingUnitType"
                      value={mailingUnitType}
                      className="[ form-control ] [ select__dropdown ]"
                      onChange={this.onChangeHandler}
                      disabled={
                        blockAddressEdit2 &&
                        !(allowAddressInfoEdit || !mailingSameAsCivic)
                      }
                      readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
                    >
                      {unitTypeList.map((e) => {
                        return (
                          <option value={e} className="[ dropdown__option ]">
                            {e}
                          </option>
                        );
                      })}
                    </select>
                    <div className="[ error-msg ]">&nbsp;</div>
                  </>
                </div>
              </div>
              <div className="[ row ]">
                <div className="[ col-md-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.STREET_NO}
                  </div>
                  <input
                    type="text"
                    name="mailingStreetNumber"
                    className="[ form-control ]"
                    value={mailingStreetNumber}
                    onChange={this.onChangeHandler}
                    disabled={
                      blockAddressEdit2 &&
                      !(allowAddressInfoEdit || !mailingSameAsCivic)
                    }
                    readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
                  />
                  <div className="[ error-msg ]">
                    {errors?.mailingStreetNumberErrorList[0]}
                  </div>
                </div>
                <div className="[ col-md-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.STREET_NAME}
                  </div>
                  <input
                    type="text"
                    name="mailingStreetName"
                    className="[ form-control ]"
                    value={mailingStreetName}
                    onChange={this.onChangeHandler}
                    disabled={
                      blockAddressEdit2 &&
                      !(allowAddressInfoEdit || !mailingSameAsCivic)
                    }
                    readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
                  />
                  <div className="[ error-msg ]">
                    {errors?.mailingStreetNameErrorList[0]}
                  </div>
                </div>
                <div className="[ col-md-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.STREET_TYPE}
                  </div>
                  <>
                    <select
                      name="mailingStreetType"
                      value={mailingStreetType}
                      className="[ form-control ] [ select__dropdown ]"
                      onChange={this.onChangeHandler}
                      disabled={
                        blockAddressEdit2 &&
                        !(allowAddressInfoEdit || !mailingSameAsCivic)
                      }
                      readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
                    >
                      {streetTypeList.map((e) => {
                        return (
                          <option value={e} className="[ dropdown__option ]">
                            {e}
                          </option>
                        );
                      })}
                    </select>
                    <div className="[ error-msg ]">&nbsp;</div>
                  </>
                </div>
                <div className="[ col-md-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.DIRECTION}
                  </div>
                  <>
                    <select
                      name="mailingStreetDir"
                      value={mailingStreetDir}
                      className="[ form-control ] [ select__dropdown ]"
                      onChange={this.onChangeHandler}
                      disabled={
                        blockAddressEdit2 &&
                        !(allowAddressInfoEdit || !mailingSameAsCivic)
                      }
                      readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
                    >
                      {directionList.map((e) => {
                        return (
                          <option value={e} className="[ dropdown__option ]">
                            {e}
                          </option>
                        );
                      })}
                    </select>
                    <div className="[ error-msg ]">&nbsp;</div>
                  </>
                </div>
              </div>
              <div className="[ row ]">
                <div className="[ col-12 col-md-6 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                    </div>
                    <>
                      <input
                        type="text"
                        name="mailingCity"
                        className={
                          errors?.mailingCityErrorList.length > 0
                            ? "[ form-control ] [ invalid-input ]"
                            : "[ form-control ]"
                        }
                        placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                        value={mailingCity}
                        onChange={this.onChangeHandler}
                        disabled={
                          blockAddressEdit2 &&
                          !(allowAddressInfoEdit || !mailingSameAsCivic)
                        }
                        readOnly={
                          !(allowAddressInfoEdit || !mailingSameAsCivic)
                        }
                      />
                    </>
                    <div className="[ error-msg ]">
                      {errors?.mailingCityErrorList[0]}
                    </div>
                  </div>
                </div>
                <div className="[ col-12 col-md-3 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
                    </div>
                    {/* <div className="[ dropdown ]"> */}
                    {mailingSelectElement}
                    {/* </div> */}
                    <div className="[ error-msg ]">
                      {errors?.mailingProvinceErrorList[0]}
                    </div>
                  </div>
                </div>
                <div className="[ col-12 col-md-3 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.COUNTRY}
                    </div>
                    {/* <div className="[ dropdown ]"> */}
                    {countrySelectElem}
                    {/* </div> */}
                    <div className="[ error-msg ]">
                      {errors?.mailingCountryErrorList[0]}
                    </div>
                  </div>
                </div>
              </div>
              <div className="[ row ]">
                <div className="[ col-12 col-md-6 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
                    </div>
                    <>
                      <MaskedInput
                        mask={mailingPostalCodePattern}
                        className={
                          errors?.mailingPostalCodeErrorList.length > 0
                            ? "[ form-control ] [ invalid-input ] [ postalCode ]"
                            : "[ form-control ] [ postalCode ]"
                        }
                        placeholder={
                          STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE
                        }
                        guide={false}
                        id="mailingPostalCode"
                        name="mailingPostalCode"
                        value={mailingPostalCode}
                        onChange={this.onChangeHandler}
                        disabled={
                          blockAddressEdit2 &&
                          !(allowAddressInfoEdit || !mailingSameAsCivic)
                        }
                        readOnly={
                          !(allowAddressInfoEdit || !mailingSameAsCivic)
                        }
                      />
                    </>
                    <div className="[ error-msg ]">
                      {errors?.mailingPostalCodeErrorList[0]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        blockBack={!!applicantData?.id}
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessKyc.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessKyc.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessKyc;
