import FIInformation from "Constants/FIInformation";

const SERVERERRORS = {
  "100001": "Sign in failed. Please try again.",
  "100002": "It seems the password you entered is incorrect.",
  "100003": `It appears your ${FIInformation.FI_APPLICATION_NAME} profile already exists. Either sign in using your existing profile credentials or create a new profile using a different email and mobile number.`,
  "100004":
    "The account credentials you've entered do not match what we have on file. Please check you have entered your credentials correclty and try again.",
  "100005":
    "Account does not exist. Check your email address was correctly entered.",
  "100000": "Permission denied.",
  "100006": `There was a problem creating account. Please try again or contact ${FIInformation.FI_NAME}, should this issue persist.`,
  "100007": `There was a problem updating account. Please try again or contact ${FIInformation.FI_NAME}, should this issue persist.`,
  "100008": `There was a problem deleting account. Please try again or contact ${FIInformation.FI_NAME}, should this issue persist.`,
  "100009":
    "Password does not match the criteria. Please check the criteria and try again.",
  "100010": `Failed to set password. Please try again or contact ${FIInformation.FI_NAME}, should this issue persist.`,
  "100011": "Your session has expired. Please sign in to continue.",
  "100012": "Your request exceeds the allowable size limit.",
  "100013":
    "Invalid request. Please check the data you have entered has been entered correctly.",
  "100014": "", // TODO:  Vault non-empty vault exceed limit
  "100015": `Your ${FIInformation.FI_APPLICATION_NAME} profile account is inactive. Please contact ${FIInformation.FI_NAME} to activate`,
  "100017": `Your ${FIInformation.FI_APPLICATION_NAME} profile account has been locked. Please contact ${FIInformation.FI_NAME}`,
  "100018":
    "It appears you are signed in on another device. This session will now end.",
  "100019": `There was a server error while refreshing your session. Please try again or contact ${FIInformation.FI_NAME} should this issue persist.`,
  "100020": "Your session has expired. Please sign in again.",
  "100101": `There was a server error while generating your verification code. Please try again or contact ${FIInformation.FI_NAME} should this issue persist.`,
  "100102":
    "Verification code expired. Hit the Resend Code button to request a new verification code.",
  "100103": "Invalid verification code. Please try again.",
  "100104": "Verification code wrong purpose",
  "100105": `Verification code request limit exceeded. Try again later or contact ${FIInformation.FI_NAME} should this issue persist.`,
  "103000": "", //
  "103003": "Unable to find applicant.",
  "500": `The server encountered an unexpected error. Please try again later or contact ${FIInformation.FI_NAME} should this issue persist.`,
  "404": `It appears we could not find what it is you are looking for. Please try again later or contact ${FIInformation.FI_NAME} should this issue persist.`,
  "103018": "Please check on joint applicant info.",
  "100026":
    "Account does not exist. Check your mobile number was correctly entered.",
};

const getErrorMessageFromErrorCode = (code, errorObj) => {
  if (SERVERERRORS[code]) {
    return `${SERVERERRORS[code]}`;
  }

  if (errorObj?.info[0]?.Message) {
    return `Error: ${errorObj.info[0].Message}`;
  }
  return `Error: ${errorObj.description}`;
};

export default getErrorMessageFromErrorCode;
