import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import VerificationCodeRequest from "Communication/ApiClasses/VerificationCodeRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import {
  TOKEN_KEY,
  IS_LOGOUT,
  S_APPLICANT_ID,
  EXPIRES_AT,
  REFRESH_EXPIRES_AT,
  S_MSISDN,
  S_EMAIL,
  S_SSO,
  S_PERSONAL,
} from "../../../Communication/Constants";

// worker
function* handleVerificationCode(action) {
  yield put(
    doLoadingStart(STRINGS.VERIFICATIONCODE.API_KEYS.VERIFICATION_CODE)
  );
  const userData = action.data;
  const verifiyApi = new VerificationCodeRequest(userData);
  try {
    const result = yield call(verifiyApi.getData);
    sessionStorage.setItem(TOKEN_KEY, result.data.token);
    sessionStorage.setItem(S_MSISDN, result.data.msisdn);
    sessionStorage.setItem(S_EMAIL, result.data.email);
    sessionStorage.setItem(S_SSO, "no");
    sessionStorage.removeItem(S_APPLICANT_ID);
    sessionStorage.removeItem(S_PERSONAL);
    sessionStorage.setItem(IS_LOGOUT, false);
    sessionStorage.setItem(EXPIRES_AT, result.data.expiresAt);
    sessionStorage.setItem(REFRESH_EXPIRES_AT, result.data.refreshExpiresAt);
    yield put({
      type: actionTypes.VERIFICATIONCODE_RESPONSE,
      success: result,
    });
    yield put({
      type: actionTypes.REGISTER_RESPONSE,
      success: "",
    });
    // yield put({
    //   type: actionTypes.GET_ACCESS_TOKEN,
    //   timeout: result.data.expiresAt,
    // });
  } catch (error) {
    yield put({
      type: actionTypes.VERIFICATIONCODE_ERROR,
      error: error.response.data.description,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.VERIFICATIONCODE.API_KEYS.VERIFICATION_CODE)
    );
  }
}

// watcher
export default function* verifyWatcherSaga() {
  yield takeLatest(
    actionTypes.VERIFICATIONCODE_REQUEST,
    handleVerificationCode
  );
}
